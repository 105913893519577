import containerAdapter from '@adapters/container'
import heroAdapter from '@adapters/hero'
import UniversalProps from '@common/UniversalProps.interface'
import Page from '@components/blocks/Page'
import Hero from '@components/global/Hero'
import Layout from '@components/layout'
import ContainerManager from '@components/shared/ContainerManager'
import fetchPage from '@utils/fetchPage'
import fetchRedirect from '@utils/fetchRedirect'
import handleRedirection from '@utils/handleRedirection'
import { getLocalizedParams } from '@utils/i18n/localize'
import isNullOrUndefined from '@utils/isNullOrUndefined'
import delve from 'dlv'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import dynamic from 'next/dynamic'

const ProgressBar = dynamic(() => import('@components/shared/ProgressBar'), { ssr: false })

const Universals = ({ globalData, pageData, preview, origin }: UniversalProps) => {
  const page = delve(pageData, 'attributes')
  const hero = heroAdapter(delve(pageData, 'attributes.hero.data'))
  hero.theme = isNullOrUndefined(hero.theme) ? page.theme : hero.theme
  const containers = (delve(pageData, 'attributes.containers.data') ?? []).map(containerAdapter)

  return (
    <Layout globalData={globalData} pageData={pageData} preview={preview} origin={origin}>
      <ProgressBar theme={page.theme} />
      <Page {...page}>
        {hero && (
          <Hero {...hero} image={hero.image}>
            {hero.containers && (
              <ContainerManager theme={hero.theme} containers={hero.containers} />
            )}
          </Hero>
        )}
        {containers && <ContainerManager containers={containers} theme={page.theme} />}
      </Page>
    </Layout>
  )
}

// This gets called on every request
export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const params = getLocalizedParams(context.query)
  const { locale } = params

  // Handle redirects
  const redirect = await fetchRedirect(context.resolvedUrl, locale, context.preview)
  if (redirect) {
    return handleRedirection(context.preview ?? false, redirect.page?.slug ?? redirect.targetUri)
  }

  // default to /home on empty slug
  const slug = params.slug === '' ? 'home' : params.slug

  try {
    const page = await fetchPage(slug, locale, context.preview)

    if (!page) {
      throw new Error('404 Page not found')
    }

    return {
      props: { pageData: page, preview: context.preview || null },
    }
  } catch (error) {
    return {
      props: { pageData: null },
      notFound: true,
    }
  }
}

export default Universals
